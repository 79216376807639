import axios from 'axios';
import {
    REQUEST_RER_EMPLOYEE_ENDPOINT,
    REQUEST_RER_ENDPOINT,
    REQUEST_RER_PUBLIC_EMPLOYEE_ENDPOINT
} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import CreateRERPayload, {CreateREREmployeePayload} from "@/dto/request/rer/CreateRERPayload";
import {PublicREREmployeeDTO} from "@/dto/request/rer/PublicREREmployeeDTO";
import RER_DTO from "@/dto/request/rer/RER_DTO";
import CompleteREREmployeePayload from "@/dto/request/rer/CompleteREREmployeePayload";
import {GetByPublicIdDTO} from "@/dto/request/rer/GetByPublicIdDTO";
import {Dictionary} from "vue-router/types/router";

class RERService {

    create(payload: CreateRERPayload){
        return axios.post(REQUEST_RER_ENDPOINT, payload, {headers: authHeader()});
    }

    getEmployeeByPublicId(id: string){
        return axios.get<PublicREREmployeeDTO>(`${REQUEST_RER_PUBLIC_EMPLOYEE_ENDPOINT}/${id}`);
    }

    saveEmployeeByPublicId(id: string, payload: PublicREREmployeeDTO){
        return axios.put(`${REQUEST_RER_PUBLIC_EMPLOYEE_ENDPOINT}/${id}`, payload);
    }

    getById(id: number){
        return axios.get<RER_DTO>(`${REQUEST_RER_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    completeEmployeeRegistration(payload: CompleteREREmployeePayload){
        return axios.put(REQUEST_RER_EMPLOYEE_ENDPOINT, payload, {headers: authHeader()});
    }

    addEmployee(payload: CreateREREmployeePayload, id: string){
        return axios.post(`${REQUEST_RER_ENDPOINT}/p/${id}/employee`, payload, {headers: authHeader()});
    }
    getByPublicId(id: string){
        return axios.get<GetByPublicIdDTO>(`${REQUEST_RER_ENDPOINT}/p/${id}`, {headers:authHeader()})
    }


}

export default new RERService();