import axios from 'axios';
import {REQUESTS_CBSR_DOCUMENTS_ENDPOINT, REQUESTS_CBSR_ENDPOINT, REQUESTS_CBSR_PUBLIC_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import CreateCBSRPayload from "@/dto/request/cbsr/CreateCBSRPayload";
import CBSR_DTO, {CBSRPeriodDTO} from "@/dto/request/cbsr/CBSR_DTO";

class CBSRService {

    createRequest(payload: CreateCBSRPayload) {
        return axios.post<number>(REQUESTS_CBSR_ENDPOINT, payload, {headers: authHeader()});
    }

    getPublic(id: string) {
        return axios.get<CBSR_DTO>(`${REQUESTS_CBSR_PUBLIC_ENDPOINT}/${id}`);
    }

    uploadCBSRDocument(publicId: string, fileMetaId: number) {
        return axios.post<void>(`${REQUESTS_CBSR_PUBLIC_ENDPOINT}/${publicId}/documents`, {fileMetaId: fileMetaId});
    }

    getById(id: number) {
        return axios.get<CBSR_DTO>(`${REQUESTS_CBSR_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    updateDocumentPeriod(docId: number, period: CBSRPeriodDTO){
        return axios.put(`${REQUESTS_CBSR_DOCUMENTS_ENDPOINT}/${docId}`, period, {headers: authHeader()});
    }

    complete(id: number){
        return axios.put(`${REQUESTS_CBSR_ENDPOINT}/${id}/complete`, {}, {headers: authHeader()});
    }

}

export default new CBSRService();